import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

// This should contain the API path
const PATH_ROOT = process.env.https://test.api.reporting.nivel.no/

// Zones
export const zonesGet = () => {
    return gateway('GET', '/zones')
}

// Get Providers
export const providersGet = () => {
    return gateway('GET', '/manager/providers')
}

// Login
export const loginSet = (data) => {
    return gateway('POST', '/manager/login', data)
}

//Cities
export const citiesGet = () => {
    return gateway('GET', '/manager/municipalities')
}

//Get all reports
export const getAllReports = (municipalityId) => {
    return gateway('GET', '/manager/report', {
        municipalityId,
    })
}

//Get report
export const reportGet = (id) => {
    return gateway('GET', `/manager/report/${id}`)
}

//Edit report
export const reportEdit = (data) => {
    return gateway('PATCH', `/manager/report/${data.id}`, data)
}

//Delete report image
export const deleteReportImage = (id) => {
    return gateway('DELETE', `/manager/report/${id}/image`)
}

//Get all alerts
export const getAllAlerts = (municipalityId) => {
    return gateway('GET', '/manager/alert', { municipalityId })
}
//Edit alert
export const alertEdit = (data) => {
    return gateway('PATCH', `/manager/alert/${data.id}`, data)
}

// GATEWAY
async function gateway(method, path, payload = null) {
    const url = PATH_ROOT + path
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
    }
    const args = { method, url, headers }
    // Handle HTTP methods
    switch (method) {
        case 'GET':
            args.params = decamelizeKeys(payload)
            break
        case 'POST':
        case 'PUT':
        case 'PATCH':
        case 'DELETE':
            args.data = decamelizeKeys(payload)
            break
    }

    // Make XHR call
    const response = await axios(args)
    return camelizeKeys(response.data)
}
